:root {
  /* == Color codes == */
  --darkest: black;
  --dark-1: #333333;
  --dark-2: #545252;
  --lightest: white;
  --lightest-blue: #e9f0f7;
  --light-blue-1: #c3d8f5;
  --light-blue-2: #6C9FE1;
  --blue: #1A73E8;
  --blue-dark-1: #005dd6;
  --green: #5c9234;

  /* BK design codes */
  --yellow: #F8C007;
  --light-yellow: #F8C007B0;
  --lighter-yellow: #F8C00780;
  --bk-green: #7EA560;
  --bk-light-green: #C7E6B0;

  /* == Purposes == */
  --gutters-wide: 30px;
  --gutters-380: 15px;
  --gutters-footer: 5%;

  --content-text: var(--dark-2);
  --content-link-text: var(--blue);
  --content-link-text-hover: var(--light-blue-2);

  --header-link-color: var(--darkest);

  /* wide */
  --header-wide-text-hover: var(--light-blue-2);
  --header-wide-text-current: var(--blue);

  /* narrow */
  --hamburger: var(--blue);
  --header-1024-item-border: var(--lightest);
  --header-1024-item-background: var(--blue);
  --header-1024-text: var(--light-blue-1);
  --header-1024-text-hover: var(--lightest);
  --header-1024-text-current: var(--darkest);
  --copyright-text: var(--darkest);
  --lang-link-text: var(--lightest);

  /* Content */
  --action-box-background: var(--bk-light-green);
  --action-button-text: var(--lightest);
}

#root {
  margin-top: 0;
  padding: 0;
  font-size: 1.15rem;
}

section {
  padding: 10px var(--gutters-wide) 0 var(--gutters-wide);
}

.legal-code-box {
  padding: 10px var(--gutters-wide) 0 var(--gutters-wide);
  padding-bottom: 10px;
  margin-bottom: 10px;

}

.category-info-box {
  background-color: var(--bk-light-green);
  padding: 10px;
  margin-bottom: 20px;

}

@media (max-width: 380px) {
  section {
    padding: 8px var(--gutters-380) 0 var(--gutters-380);
  }
}

h1, h2, h3 {
  color: black;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 1.5rem;
}

h2, h3 {
  font-size: 1.25rem;
}

.App {
  color: var(--content-text);
}

.App a {
  color: var(--content-link-text);
  font-weight: bold;
}

.App a:hover {
  color: var(--content-link-text-hover);
}

.App a,
.App a:active,
.App a:link,
.App a:visited,
.App a:hover {
  text-decoration: none;
}

.App p {
  margin-top: 0;
  margin-bottom: 0.9rem;
}

.row {
  display: flex;
  flex: 1;
  margin: 0 auto;
  max-width: 700px;
}

.column {
  flex: 50%;
  margin-right: 20px;
}

.divider {
  border-bottom: 1px solid black;
}

.divider-container {
  padding: 10px var(--gutters-wide) 0 var(--gutters-wide);
}

@media (max-width: 380px) {
  .divider-container {
    padding: 8px var(--gutters-380) 0 var(--gutters-380);
  }
}

.normal .link {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: black;
}

.link {
  color: var(--blue);
}

.link-no-decoration,
.link-no-decoration:active,
.link-no-decoration:link,
.link-no-decoration:visited {
  text-decoration: none;
}

.link-no-decoration:hover {
  text-decoration: underline;
}

.call-to-action-link,
.call-to-action-link:hover,
.call-to-action-link:active,
.call-to-action-link:link,
.call-to-action-link:visited {
  color: white;
}

/* ====== App ====== */
.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin: 0 auto;
}

/* === Language menu === */

.topnav {
  top: 0;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0;
  padding: 0 2rem;
  display: flex;
  justify-content: flex-end;
  background-color: var(--blue);
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 0.8em;
}

#language-container {
  padding: .7rem 1rem;
  display: flex;
  justify-content: space-between;
  line-height: 1.5em;
}

.language-link {
  padding: .5rem;
  font-weight: 600;
  color: var(--lang-link-text);
}

@media (max-width: 767px) {
  .topnav {
    justify-content: center;
  }
}

/* === Header with menu === */

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#header_section {
  position: relative;
}

.header-part {
  padding: 10px;
}

.header-part:first-child {
  padding-left: 0;
}

.header-part:last-child {
  padding-right: 0;
}

/* == Header logo == */

#header .logo-container {
  width: 220px;
  text-align: left;
}

@media (max-width: 380px) {
  #header .logo-container {
    width: 55%;
  }
}

#header .logo-container img {
  width: 100%;
}

/* == Header nav links containers == */

#header_nav {
  display: flex;
  flex: 1;
}

/* Show/hide hamburger stuff */
#header_nav_container_1024 {
  display: none;
}

@media (max-width: 1024px) {
  #header_nav_container_wide {
    display: none;
  }
  #header_nav_container_1024 {
    display: block;
  }
}

/* == Actual nav list containers == */

/* - wide - */
#header_nav_container_wide #header_nav {
  flex-direction: row;
  justify-content: space-between;
}

/* - narrow - */
#header_nav_container_1024 #header_nav {
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
}

/* Expand and collapse hamburger's menu*/
#header_nav_container_1024 #header_nav {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease;
}

/* WARNING: If the menu gets too much longer, this height must be increased */
#header_nav_container_1024 #header_nav.expanded {
  max-height: 300px;
}

/* == Header nav link lists == */

#header_nav ul {
  display: flex;
  /* Neutralize default ul styles */
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

#header_nav_container_wide #header_nav ul {
  flex-direction: row;
}

#header_nav ul li {
  /* Neutralize default li styles */
  display: inline;
  list-style-type: none;
}

/* - wide - */

#header_nav_container_wide #header_nav li {
  padding: 0 20px;
}

#header_nav_container_wide #header_nav li:first-child {
  padding-left: 0;
}

#header_nav_container_wide #header_nav li:last-child {
  padding-right: 0;
}

/* - narrow - */

#header_nav_container_1024 #header_nav ul {
  flex-direction: column;
}

/* == Header nav links == */

#header_nav .header-link {
  /* Override link styles */
  display: block;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.05rem;
  font-weight: 700;
  color: var(--header-link-color);
}

#header_nav .header-link,
#header_nav .header-link:active,
#header_nav .header-link:link,
#header_nav .header-link:visited,
#header_nav .header-link:hover {
  text-decoration: none;
}

#header_nav .header-link span {
  margin: 0;
}

.hamburger {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hamburger);
  border: 1px solid var(--hamburger);
  border-radius: 3px;
  font-size: 25px;
}

/* - wide - */

#header_nav_container_wide #header_nav .header-link-text:hover {
  color: var(--header-wide-text-hover);
}

/* Styles for current header item are different that items and don't change with hover */
#header_nav_container_wide #header_nav .header-item-current span,
#header_nav_container_wide #header_nav .header-item-current span:hover {
  color: var(--blue);
}

#header_nav_container_wide .header-link-text {
  margin: 0;
  padding: 0px 5px 0px 5px;
}

/* - narrow - */

#header_nav_container_1024 #header_nav .header-link {
  padding: 1em;
}

#header_nav_container_1024 #header_nav li {
  background-color: var(--header-1024-item-background);
}

#header_nav_container_1024 #header_nav li span {
  color: var(--header-1024-text);
}

#header_nav_container_1024 #header_nav li:hover span {
  color: var(--header-1024-text-hover);
}

#header_nav_container_1024 #header_nav li {
  border-bottom: 1px solid var(--header-1024-item-border);
}

#header_nav_container_1024 #header_nav li:first-child {
  border-top: 1px solid var(--header-1024-item-border);
}

/* Styles for current header item are different that items and don't change with hover */
#header_nav_container_1024 #header_nav .header-item-current span,
#header_nav_container_1024 #header_nav .header-item-current:hover span {
  color: var(--header-1024-text-current);
}

/* === Footer === */

section#footer {
  padding-left: var(--gutters-footer);
  padding-right: var(--gutters-footer);
  margin-top: 50px;
}

#footer_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.15rem;
  line-height: 1.5em;
}

#footer .logo img {
  max-width: 100%;
  height: 35px
}

#footer_resources {
  text-align: right;
}

#footer_divider {
  padding-left: var(--gutters-footer);
  padding-right: var(--gutters-footer);
}

#copyright {
  margin-top: 0;
  margin-bottom: 50px;
  padding-left: var(--gutters-footer);
  padding-right: var(--gutters-footer);
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 300;
  /* Closest I can get to visual appearance at narrow widths */
  line-height: 2em;
  color: var(--copyright-text);
  /* Avoid margin collapse */
  display: flex;
}

#copyright p {
  text-align: left;
}

/* Bottom area narrow widths */

/* Below 768 */
@media (max-width: 768px) {

  #footer .logo {
    line-height: 1em;
  }

  #footer_resources {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  #footer_container {
    flex-direction: column;
  }

  #footer_divider {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #copyright {
    justify-content: center;
    padding-top: 0;
  }
}

/* Below 1024 */
@media (max-width: 1024px) {
  section#footer {
    padding-top: 0;
    margin-top: 100px;
  }
}

/* Above 768 */
@media (min-width: 768px) {
  #footer_resources {
    width: 22.495%;
  }

  #footer .logo {
    max-width: 19.198%;
  }
}


/* === Action boxes === */

.action-box {
  background-color: var(--action-box-background);
  width: 100vw;
  margin: 6% 0 20px calc(50% - 50vw);
  font-size: 1.15rem;
  padding: 6% 0;
}

.action-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
}

.action-box h2 {
  font-size: 1.5em;
  line-height: 1.2em;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  color: var(--dark-1);
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
}

.action-box button {
  margin: 0;
  padding: 12px 24px;
}

.action-box a {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--action-button-text);
  line-height: 1;
  transition: all .3s;
  padding: 12px 24px;
}

@media (max-width: 767px) {
  .action-box {
    padding: 0;
  }

  .action-body {
    padding: 20px;
  }

  .action-box h2 {
    font-size: 1.35em;
  }
}


/* === Categories === */
.category-button {
  font-size: 17px;
  height: 50%;
  padding: 5% 10% 5% 5%;
  background-color: var(--light-yellow);
  border: 0px solid transparent;
  margin: 5% auto;
  width: 90%;
  color: black;
  display: flex;
  flex: 1;
  justify-content: space-between;
  text-align: left;
  font-family: "Quicksand", sans-serif;
  border-radius: 5px;
  overflow: hidden;
}

.category-button:hover {
  cursor: pointer;
  background-color: var(--yellow);

}

.category-button::after {
  content: ">";
}
@media (max-width: 767px) {
  .category-button {
    height: 70%;
  }
}
.button {
  font-size: 17px;
  text-align: left;
  padding: 10px;
  background-color: var(--blue);
  border: 0 solid transparent;
  margin: 5px;
  color: white;
  display:  inline-block;
  border-radius: 6px 6px 6px 6px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.button:hover {
  cursor: pointer;
}

.icon {
  font-size: 24px;
  background-color: #1A73E8;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
  display: inline-block;
}


/* === Info box === */

.info-box {
  background-color: var(--bk-light-green);
  width: 50%;
  margin: 10px auto;
  padding: 10px 15px;
  border-radius: 5px;
}

.info-box-yellow {
  background-color: var(--yellow);
  width: 50%;
  margin: 10px auto;
  padding: 10px 15px;
  border-radius: 5px;
}
